
import {
  Vue,
  Component, Prop, Inject,
} from 'vue-property-decorator';
import { replaceUrl } from '@/helpers/converters/url';
import DataTableHandler from '@/components/DataTable/DataTable';
import Message from '@/components/mixins/Message.vue';
import Row from '@/helpers/mappers/DataTable/DataTableRow';
import { LabelItems } from '@/components/commonComponents/Tags.vue';

import { DatatableLink } from '@/types/ListTypes';
import { Value } from '@/types/DataTableTypes';
import Tooltip from '@/components/Tooltip/Tooltip.vue';

// Lazy loading imports
const Assignments = () => import(
  /* webpackChunkName: "assignments" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Assignments.vue'
);
const Tags = () => import(
  /* webpackChunkName: "tags" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Tags.vue'
);
const DataTableEditableField = () => import(
  /* webpackChunkName: "data-table-editable-field" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableEditableField/DataTableEditableField.vue'
);
const Icon = () => import(
  /* webpackChunkName: "icon" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Icon.vue'
);

@Component({
  name: 'DataTableCell',
  components: {
    Icon,
    Tooltip,
    Assignments,
    Tags,
    DataTableEditableField,
  },
  data: () => ({
    blob: { } as File,
    name: 'Image not found',
    base64: null,
    height: 200,
  }),
})
export default class DataTableCell extends Vue {
  @Prop({ required: true })
  private readonly dataTable!: DataTableHandler;

  @Prop({ required: true })
  private readonly columnIndex!: number;

  @Prop({ required: true })
  private readonly rowIndex!: number;

  @Prop({ required: true })
  private readonly columnHeaderName!: string;

  @Prop({ required: true })
  private readonly dataTableRow!: Row;

  @Prop()
  private readonly blockId?: number;

  @Prop()
  public readonly navigationSlug?: string;

  @Prop()
  public readonly dossierTypeSlug?: string;

  @Inject('DataTable.changeOrder')
  private changeOrder!: (rowIndex: number, number: number, type: 'swap'|'move') => void;

  private isAssign(columnIndex: number): boolean {
    const columnName = this.dataTable.dataTableMapper?.inputHeader[columnIndex].name;
    const moduleColumns = this.dataTable.getModuleColumns();

    if (typeof moduleColumns?.assignments !== 'undefined' && columnName !== undefined) {
      return moduleColumns.assignments.includes(columnName);
    }
    return false;
  }

  private isTag(columnIndex: number): boolean {
    const columnName = this.dataTable.dataTableMapper?.inputHeader[columnIndex].name;
    const moduleColumns = this.dataTable.getModuleColumns();

    if (moduleColumns?.tags !== undefined && typeof columnName === 'string') {
      return moduleColumns.tags.includes(columnName);
    }

    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  private getTags(row: Row, columnName: string): LabelItems {
    return row.getCell(columnName).getValue() as LabelItems;
  }

  private isUrl(columnIndex: number): boolean {
    const columnName = this.dataTable.dataTableMapper?.inputHeader[columnIndex].name;

    if (typeof columnName === 'string') {
      const columnSettings = this.dataTable.getColumnSettings()?.[columnName];
      return Boolean((columnSettings && columnSettings.display_type));
    }

    return false;
  }

  private isLink(columnName: string, index: number): boolean {
    const columnSettings = this.dataTable.getColumnSettings();
    const listSettings = this.dataTable.getListSettings();
    const rowSettings = this.dataTable.getRowSettings();
    const { data } = this.$props.dataTable.dataTableMapper.inputData[index];

    if (rowSettings?.[index]?.is_link === true) {
      return true;
    }

    // Used in modules e.g. tasks and contact moments
    if (typeof columnSettings === 'undefined') {
      if (this.isReport() && !data.data_id) {
        return false;
      }
      // In this case all columns will be a link if there is a link object available on the list
      if (typeof listSettings?.link !== 'undefined') {
        return true;
      }
    }

    // Used for some system columns e.g. labels in report list
    if (typeof columnSettings?.[columnName] === 'undefined') {
      return false;
    }

    // List with settings available for links
    if (columnSettings?.[columnName]?.is_link) {
      return true;
    }

    return false;
  }

  private isEditable(columnIndex: number): boolean {
    const columnName = this.dataTable.dataTableMapper?.inputHeader[columnIndex].name ?? '';

    const inlineEditableForm = this.dataTable.getInlineEditableForms()?.forms?.[columnName];
    return inlineEditableForm !== undefined;
  }

  protected isPreview(columnName: string, value: Value): boolean {
    const columnSettings = this.dataTable.getColumnSettings();

    // Used for some system columns e.g. labels in report list
    if (typeof columnSettings?.[columnName] === 'undefined') {
      return false;
    }

    if (columnSettings?.[columnName]?.type === 'filePreview') {
      this.$data.height = columnSettings?.[columnName]?.height ?? 200;
      this.createFilePreview(value as string);
      return true;
    }

    return false;
  }

  private isIcon(columnName: string): boolean {
    const columnSettings = this.dataTable.getColumnSettings();

    // Used for some system columns e.g. labels in report list
    if (typeof columnSettings?.[columnName] === 'undefined') {
      return false;
    }

    return columnSettings?.[columnName]?.type === 'icon';
  }

  // eslint-disable-next-line class-methods-use-this
  private isSortable(columnIndex: number): boolean {
    const columnName = this.dataTable.dataTableMapper?.inputHeader[columnIndex].name;
    const columnSettings = this.dataTable.getColumnSettings();

    if (typeof columnName !== 'string'
      || typeof columnSettings === 'undefined'
      || columnSettings === {}
      || typeof columnSettings[columnName] === 'undefined'
    ) {
      return false;
    }

    if (typeof columnSettings[columnName]?.position_sorting === 'boolean') {
      return columnSettings[columnName].position_sorting === true;
    }

    return false;
  }

  private handleClick(index: number, columnName: string, columnIndex: number, target: '_self'|'_blank' = '_self'): void {
    const { data } = this.$props.dataTable.dataTableMapper.inputData[index];
    const listSettings = this.$props.dataTable.getListSettings();
    const columnSettings = this.$props.dataTable.getColumnSettings();
    const rowSettings = this.dataTable.getRowSettings();

    let link: DatatableLink|null|undefined;
    if (listSettings?.link !== undefined) {
      link = listSettings.link;
    }

    if (rowSettings?.[index]?.is_link && rowSettings?.[index]?.link !== undefined) {
      link = rowSettings[index].link;
    }
    if (![null, undefined].includes(columnSettings?.[columnName]?.link)) {
      link = columnSettings[columnName].link;
    }

    let routerData: { [key: string]: string|{[key: string]: string }}|undefined;

    if (this.isUrl(columnIndex)) {
      let url = data[columnName];
      if (!url.startsWith('/') && !url.startsWith('http')) {
        url = `//${data[columnName]}`;
      }
      window.open(url, columnSettings[columnName]?.target ?? target, 'noreferrer');
    } else if (
      this.dataTable.getFileSettings() !== undefined
      && Object.values(this.dataTable.getFileSettings()).length > 0
      && (
        typeof this.dataTable.dataTableMapper?.headers !== 'undefined'
        && Object.values(this.dataTable.getFileSettings())[0].includes(this.dataTable.dataTableMapper?.headers[columnIndex].text)
      )
    ) {
      // Download file
      // TODO: Must be made into a list object in the API
      this.downloadFile(data[Object.keys(this.dataTable.getFileSettings())[0]]);
    } else if (link?.type === 'navigationItem') {
      // List link
      // Used in all dossier lists, dashboard lists en report lists
      routerData = {
        name: 'dossier-detail',
        params: {
          navigationGroup: link.data.navigationGroup,
          dossierTypeSlug: link.data.dossierTypeSlug,
          navigationSlug: link.data.navigationSlug,
          dossierId: data.id,
        },
      };
    } else if (link?.type === 'url') {
      // Full URL link
      // for example used in the search function
      const urlData = {
        ...data,
        ...link.data,
      };

      const url = `${replaceUrl(link?.url, urlData)}`;

      routerData = {
        path: url,
      };
    } else if (link?.type === 'dossierDetail' || link?.type === 'dialog') {
      // Dialog or dossierDetail link
      // These are separate types because it might be smart to treat them separately in the future
      const urlData = {
        ...data,
        ...link.data,
      };

      const url = replaceUrl(link.url, urlData);

      let currentHash = this.$route.hash;
      if (this.$route.hash.length === 0) {
        currentHash = window.location.hash.replace('#', '');
      }

      let newHash = currentHash;
      if (newHash.length > 0) {
        newHash = `${currentHash}|${url}`;
      } else {
        newHash = url;
      }

      routerData = {
        hash: decodeURI(newHash),
      };
    } else if (this.isPreview(columnName, this.dataTableRow.getCell(columnName).getValue())) {
      this.downloadFile(this.dataTableRow.getCell(columnName).getValue() as string);
    }

    if (routerData === undefined) {
      return;
    }

    if (target === '_self' && this.$route.path !== routerData.path) {
      // Most used option for links which will be resolved by the vue router
      this.$router.push(routerData);
    } else if (target === '_blank') {
      // Open in new window, most likely used by CTRL+ click action or external URL
      window.open(this.$router.resolve(routerData).href, target);
    }

    this.$emit('click');
  }

  private downloadFile(id: string) {
    let fileName = '';
    let fileType = '';

    import('@/graphql/queries/download-file')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables: {
          id,
        },
      }))
      .then((response) => {
        const base64 = response.data.file.content;
        fileName = response.data.file.filename;
        fileType = 'image/jpg';
        return fetch(`data:${fileType};base64,${base64}`);
      })
      .then((res) => res.blob())
      .then((blobImage) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blobImage);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      });
  }

  private createFilePreview(id: string|null) {
    const variables = {
      id,
    };

    import('@/graphql/queries/download-file')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
      }))
      .then(async (response) => {
        if (response.data.file === null) {
          return null;
        }
        const { filename, content } = response.data.file;
        const type = 'image/png';
        const imageObject = await fetch(`data:${type};base64,${content}`);
        const blob = await imageObject.blob();

        this.$data.name = filename;
        this.$data.blob = { filename, type, blob };

        return blob;
      })
      .then(async (blob) => {
        if (blob === null) {
          return;
        }
        this.$data.base64 = `data:image/jpeg;base64,${btoa(
          new Uint8Array(await blob.arrayBuffer())
            .reduce((data, byte) => data + String.fromCharCode(byte), ''),
        )}`;
      });
  }

  private isReport(): boolean {
    const { navigationGroup } = this.$route.params;
    switch (navigationGroup as string) {
      case 'status':
      case 'label':
      case 'assign':
      case 'toewijzen':
      case 'groep':
      case 'group':
        return true;
      default:
        return false;
    }
  }
}
