
import {
  Component,
  Prop,
  Vue,
  Provide,
} from 'vue-property-decorator';
import Sortable, { SortableEvent } from 'sortablejs';
import DataTableHandler from '@/components/DataTable/DataTable';
import { VNode } from 'vue';
import DataTableCell from '@/components/DataTable/DataTableCell/DataTableCell.vue';
import Message from '@/components/mixins/Message.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { DocumentNode } from 'graphql';

// Lazy loading imports
const SelectedFilterValues = () => import(
  /* webpackChunkName: "selected-filter-values" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/SelectedFilterValues.vue'
);
const MultiselectFilter = () => import(
  /* webpackChunkName: "multiselect-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/MultiselectFilter.vue'
);
const SearchFilter = () => import(
  /* webpackChunkName: "search-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/SearchFilter.vue'
);
const SelectFilter = () => import(
  /* webpackChunkName: "select-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/SelectFilter.vue'
);
const NumberFilter = () => import(
  /* webpackChunkName: "number-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/NumberFilter.vue'
);
const CurrencyFilter = () => import(
  /* webpackChunkName: "currency-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/CurrencyFilter.vue'
);
const DateFilter = () => import(
  /* webpackChunkName: "date-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/DateFilter.vue'
);
const TagsFilter = () => import(
  /* webpackChunkName: "tags-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/TagsFilter.vue'
);
const AssignmentsFilter = () => import(
  /* webpackChunkName: "assignments-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/AssignmentsFilter.vue'
);
const BooleanFilter = () => import(
  /* webpackChunkName: "boolean-filter" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/BooleanFilter.vue'
);

@Component({
  name: 'DataTable',
  components: {
    SelectedFilterValues,
    DataTableCell,
    Tooltip,
    MultiselectFilter,
    SearchFilter,
    SelectFilter,
    NumberFilter,
    CurrencyFilter,
    DateFilter,
    TagsFilter,
    AssignmentsFilter,
    BooleanFilter,
  },
  directives: {
    sortableDataTable: {
      bind(el: HTMLElement, _: unknown, vnode: VNode): void {
        const options = {
          filter: '.static',
          animation: 150,
          onUpdate: (event: SortableEvent) => {
            if (typeof vnode.componentInstance !== 'undefined') {
              vnode.componentInstance.$emit('sorted', event);
            }
          },
          onMove(e: { related: { className: string | string[]; }; }) {
            return e.related.className.indexOf('static') === -1;
          },
          handle: '.datatable-sortable-cell-handle',
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },
})
export default class DataTable extends Vue {
  @Prop({ required: true })
  private readonly dataTable!: DataTableHandler;

  @Prop()
  private readonly blockId?: number;

  @Prop()
  public readonly navigationSlug?: string;

  @Prop()
  public readonly dossierTypeSlug?: string;

  updated(): void {
    this.checkPriorityColumns();
  }

  mounted(): void {
    window.addEventListener('resize', this.checkPriorityColumns);
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.checkPriorityColumns);
  }

  private resetCurrentPage(): void {
    this.dataTable.resetCurrentPage();
  }

  private async checkPriorityColumns(): Promise<void> {
    this.dataTable.showAllColumns();
    await this.$nextTick();
    const maxWidth = this.$el.clientWidth;
    let tableWidth = this.$el.getElementsByTagName('table')[0].scrollWidth;

    // Remove from the most right to left column per priority. The 'high-priority' and 'no-priority' will always stay visible
    ['low-priority', 'medium-priority'].forEach((priority) => {
      const headersWithCurrentPriority = this.dataTable.headers
        .filter((header) => header.class?.includes(priority))
        .reverse();

      for (let i = 0; i < headersWithCurrentPriority.length && tableWidth > maxWidth; i += 1) {
        const headerValue = headersWithCurrentPriority[i].value;
        const headerWidth = (this.$refs[headerValue] as Array<HTMLElement>)?.[0]?.clientWidth;

        this.dataTable.hideColumn(headerValue);
        tableWidth -= headerWidth;
      }
    });
  }

  protected setSorter(columnName: string, currentSortBy: string|undefined, currentSortDesc: boolean): void {
    if (this.dataTable.headers.find((header) => header.value === columnName)?.sortable === false) {
      return;
    }
    const initialSortDesc = this.dataTable.getColumnSettings()?.[columnName]?.initial_sorting_order === 'desc';

    if (initialSortDesc) {
      if (currentSortBy !== columnName) {
        // New column selected. Sort column descending
        this.dataTable.options = { ...this.dataTable.options, ...{ sortDesc: [true], sortBy: [columnName] } };
      } else if (currentSortBy === columnName && currentSortDesc) {
        // Current sorted column selected again. Reverse current sorting if current sorting isn't descending
        this.dataTable.options = { ...this.dataTable.options, ...{ sortDesc: [false], sortBy: [columnName] } };
      } else {
        // Current sorted column clicked for the 3rd time. Reset list sorting
        this.dataTable.resetInitialSorting();
      }
    }

    if (!initialSortDesc) {
      if (currentSortBy !== columnName) {
        // New column selected. Sort column ascending
        this.dataTable.options = { ...this.dataTable.options, ...{ sortDesc: [false], sortBy: [columnName] } };
      } else if (currentSortBy === columnName && !currentSortDesc) {
        // Current sorted column selected again. Reverse current sorting if current sorting isn't ascending
        this.dataTable.options = { ...this.dataTable.options, ...{ sortDesc: [true], sortBy: [columnName] } };
      } else {
        // Current sorted column clicked for the 3rd time. Reset list sorting
        this.dataTable.resetInitialSorting();
      }
    }
  }

  private getTotalCounter(columnName: string): number {
    return this.dataTable.getAggregations()?.[columnName]?.sum;
  }

  private getAverageCounter(columnName: string): number {
    return this.dataTable.getAggregations()?.[columnName]?.average;
  }

  private hasTotalCounter(columnName: string|null = null): boolean {
    if (columnName === null) {
      return this.dataTable.headers.some(({ value: name }) => this.dataTable.getColumnSettings()?.[name]?.calculate_totals);
    }
    return this.dataTable.getColumnSettings()?.[columnName]?.calculate_totals === true;
  }

  private hasAverageCounter(columnName: string|null = null): boolean {
    if (columnName === null) {
      return this.dataTable.headers.some(({ value: name }) => this.dataTable.getColumnSettings()?.[name]?.calculate_average);
    }
    return this.dataTable.getColumnSettings()?.[columnName]?.calculate_average === true;
  }

  private isCurrencyHeader(columnName: string): boolean {
    return this.dataTable.getColumnSettings()?.[columnName]?.type === 'currency';
  }

  private importPositionMutation(): Promise<{default: DocumentNode}> {
    if (typeof this.blockId !== 'undefined') {
      return import('@/graphql/mutations/dossier-type-list-handle-position-field-by-block-id');
    }
    return import('@/graphql/mutations/dossier-type-list-handle-position-field');
  }

  @Provide('DataTable.changeOrder')
  protected async changeOrder(oldIndex: number, newIndex: number): Promise<void> {
    const oldRow = this.dataTable.rows[oldIndex];
    const newRow = this.dataTable.rows[newIndex];
    const columnSettings = this.dataTable.getColumnSettings();
    const positionColumn = Object.keys(columnSettings).find((key) => columnSettings[key]?.type === 'position');
    if (positionColumn === undefined) {
      throw new Error('Somethings went wrong while changing the row position.');
    }

    const variables = {
      navigationSlug: this.$route.params?.navigationSlug,
      dossierTypeSlug: this.$route.params?.dossierTypeSlug,
      blockId: this.blockId,
      data: {
        data_id: oldRow.getCell('data_id').getValue(),
        id: oldRow.getCell('id').getValue(),
        new_data_id: newRow.getCell('data_id').getValue(),
      },
    };

    return this.importPositionMutation()
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then(() => {
        Message.success(this.$t('save.notification'));
        this.$emit('fetchData');
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      });
  }
}
