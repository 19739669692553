import Row from '@/helpers/mappers/DataTable/DataTableRow';
import {
  Header as DataTableHeader,
  DataTableOptions,
  FooterProperties,
  MetadataRowSettings,
  PaginationProperties,
  FilterType,
} from '@/types/DataTableTypes';
import {
  DossierTypeBulkAction, DossierTypeListBulkAction,
  Filter,
  Filters,
  List,
  Metadata, MetadataActions,
  MetadataAggregations, MetadataColumnsSettings,
  MetadataFileSettings, MetadataInlineEditableForms, MetadataListSettings,
  MetadataModuleColumns, MetadataReportSettings, PaginatorInfo,
  Sorter, Sorters, WorkflowBulkAction,
} from '@/types/ListTypes';
import DataTableMapper from '@/helpers/mappers/DataTable/DataTableMapper';
import { isEmptyArray } from '@/helpers/arrayHelpers';

export interface GraphQLResponse {
  list: List,
  listTitle?: string,
}

export default class DataTable {
  rows: Array<Row> = [];

  headers: Array<DataTableHeader> = [];

  options: DataTableOptions = {};

  showFirstLastPage = true;

  itemsPerPageOptions = [10, 20, 30, 40, 50, 100];

  itemsPerPage = 20;

  total = 0;

  hasTotals = false;

  paginationProperties: PaginationProperties = {
    isEnabled: false,
  };

  hideDefaultHeader = false;

  sortBy: string | undefined = '';

  sortDesc = false;

  bulkActions: Array<DossierTypeListBulkAction|DossierTypeBulkAction|WorkflowBulkAction> = [];

  bulkSelection: Array<Row> = [];

  showSelect = false;

  metadata: Metadata = {};

  dataTableMapper: DataTableMapper | undefined;

  private filters: Filters = [];

  private sorters: Sorters = [];

  private page = 1;

  private firstItem = 0;

  footerRows: Array<Row> = [];

  private readonly identifier: string|undefined;

  constructor(identifier: string|undefined = undefined) {
    if (identifier) {
      this.identifier = `${identifier}${window.location.pathname}`;

      const filters = sessionStorage.getItem(`filters.${this.identifier}`);
      if (filters !== null) {
        this.filters = JSON.parse(filters) as Filters;
      }

      const sorters = sessionStorage.getItem(`sorters.${this.identifier}`);
      if (sorters !== null) {
        this.sorters = JSON.parse(sorters) as Sorters;
        this.updateSorting();
      }
    }
  }

  get footerProps(): FooterProperties {
    return {
      showFirstLastPage: this.showFirstLastPage,
      'items-per-page-options': this.itemsPerPageOptions,
    };
  }

  public resetCurrentPage(): void {
    this.options.page = 1;
  }

  private static mergeClasses(
    firstClassList: undefined|string|Array<string>,
    secondClassList: undefined|string|Array<string>,
  ): Array<string> {
    let classList: Array<string> = [];
    if (firstClassList !== undefined) {
      const firstArray = typeof firstClassList === 'string' ? [firstClassList] : [...firstClassList];
      classList = [...classList, ...firstArray];
    }
    if (secondClassList !== undefined) {
      const secondArray = typeof secondClassList === 'string' ? [secondClassList] : [...secondClassList];
      classList = [...classList, ...secondArray];
    }

    return classList;
  }

  public hideColumn(columnValue: string): void {
    const header = this.headers.find((h) => h.value === columnValue);

    if (header === undefined) {
      throw Error(`no column found to hide, searching for header with value '${columnValue}'`);
    }

    header.class = DataTable.mergeClasses(header?.class, 'd-none');
    header.cellClass = DataTable.mergeClasses(header?.cellClass, 'd-none');
  }

  private static removeClass(classList: undefined|string|Array<string>, classToRemove: string): Array<string> {
    if (classList === undefined) {
      return [];
    }
    const classArray = typeof classList === 'string' ? [classList] : [...classList];

    return classArray.filter((className) => className !== classToRemove);
  }

  public showAllColumns(): void {
    const classToRemove = 'd-none';
    const hiddenColumns = this.headers.filter((header) => header.class?.includes(classToRemove));

    for (let i = 0; i < hiddenColumns.length; i += 1) {
      hiddenColumns[i].class = DataTable.removeClass(hiddenColumns[i].class, classToRemove);
      hiddenColumns[i].cellClass = DataTable.removeClass(hiddenColumns[i].cellClass, classToRemove);
    }
  }

  private updateSorting(): void {
    const activeSorter = this.sorters.find((sorter: Sorter) => sorter.applied ?? false);

    if (typeof activeSorter !== 'undefined') {
      this.sortBy = activeSorter.name;
      this.sortDesc = activeSorter.direction === 'desc';
    }
  }

  public hasTooltip(headerValue: string): boolean {
    return this.getTooltipText(headerValue) !== '';
  }

  public getTooltipText(headerValue: string): string {
    return this.getColumnSettings()?.[headerValue]?.help ?? '';
  }

  public getFilterType(columnName: string): FilterType {
    const filterType = this.getFilter(columnName)?.metadata?.type;

    switch (filterType) {
      case 'multiselect':
        return 'MultiselectFilter';
      case 'select':
        return 'SelectFilter';
      case 'range':
        return 'NumberFilter';
      case 'currency-range':
        return 'CurrencyFilter';
      case 'date-range':
      case 'datetime-range':
        return 'DateFilter';
      case 'tags':
        return 'TagsFilter';
      case 'assignments':
        return 'AssignmentsFilter';
      case 'boolean-select':
        return 'BooleanFilter';
      default:
        return 'SearchFilter';
    }
  }

  public getBulkSelection(): Array<number> {
    return this.bulkSelection.map((row) => {
      if (typeof row.getCell('id').getValue() === 'number') {
        return row.getCell('id').getValue() as number;
      }
      return row.getCell('data_id').getValue() as number;
    });
  }

  public refreshPaginationOptions(): void {
    if (this.itemsPerPage !== this.options.itemsPerPage) {
      this.options.page = Math.floor(this.firstItem / (this.options.itemsPerPage ?? 20)) + 1;
      this.page = this.options.page;
      this.itemsPerPage = this.options.itemsPerPage ?? 20;
    } else if (this.page !== this.options.page) {
      this.page = (this.options.page ?? 1);
    } else if (this.sorters !== this.getSorters()) {
      this.page = this.options.page;
      this.itemsPerPage = this.options.itemsPerPage;
      this.sorters = this.getSorters();
    }
  }

  public handleData(response: GraphQLResponse): void {
    const activeSorter = response.list.dataGridInfo.sorters?.find((sorter: Sorter) => sorter.applied ?? false);
    this.sortBy = activeSorter?.name ?? '';
    this.sortDesc = activeSorter?.direction === 'desc';
    this.metadata = response.list.dataGridInfo?.metadata;

    this.setPaginatorInfo(response.list.paginatorInfo);
    this.setFilters(response.list.dataGridInfo.filters);
    this.setSorters(response.list.dataGridInfo.sorters);

    this.dataTableMapper = DataTable.mapDatatable(response.list);
    this.headers = this.dataTableMapper.getHeaders();
    this.rows = this.dataTableMapper.getRows();

    this.paginationProperties.isEnabled = this.getListSettings()?.pagination ?? true;

    this.bulkActions = (this.getMetadata('actions') ?? []) as MetadataActions;
    this.bulkSelection = []; // empty the selection every time new data is handled
    this.showSelect = !isEmptyArray(this.bulkActions);

    this.setFooterRows(response.list);
  }

  public setFooterRows(list: List): void {
    const average: Record<string, unknown> = {};
    const totals: Record<string, unknown> = {};

    this.headers.forEach((column) => {
      const columnName = column.value;
      const aggregation = this.getAggregations()?.[columnName];

      average[columnName] = aggregation && aggregation.average !== undefined
        ? { value: aggregation.average, properties: { class: 'average-footer-row' } }
        : { value: null };

      totals[columnName] = aggregation && aggregation.sum !== undefined
        ? { value: aggregation.sum, properties: { class: 'total-footer-row' } }
        : { value: null };
    });

    const data = [];
    if (Object.values(Object.values(average)).filter((n) => (n as { value: unknown }).value !== null).length > 0) {
      data.push({ data: average });
    }

    if (Object.values(Object.values(totals)).filter((n) => (n as { value: unknown }).value !== null).length > 0) {
      data.push({ data: totals });
    }

    const dataTableMapper = DataTable.mapDatatable({
      data,
      dataGridInfo: list.dataGridInfo,
    });
    dataTableMapper.map();

    this.footerRows = dataTableMapper.getRows();
  }

  public getFilter(columnName: string): Filter {
    return this.filters.find((filter: Filter) => (filter.name === columnName)) as Filter;
  }

  private setSorters(sorters: Sorters|undefined): void {
    if (sorters === undefined) {
      return;
    }
    this.sorters = sorters
      .map(({ name, applied, direction }) => ({ name, applied, direction }))
      .filter((sorter) => sorter.applied);

    if (typeof this.identifier === 'string') {
      sessionStorage.setItem(`sorters.${this.identifier}`, JSON.stringify(this.sorters));
    }

    this.updateSorting();
  }

  public resetInitialSorting(): void {
    let sortBy = this.getListSettings().sort_column as string|null|undefined;
    let sortDesc = this.getListSettings().sort_order === 'desc';

    Object.keys(this.getColumnSettings()).forEach((columnName: string) => {
      if ((sortBy === null || sortBy === undefined) && this.getColumnSettings()?.[columnName]?.sortable === true) {
        sortBy = columnName;
        sortDesc = this.getColumnSettings()?.[columnName]?.initial_sorting_order === 'desc';
      }
    });

    this.options = {
      ...this.options,
      ...{
        sortBy: [sortBy as string],
        sortDesc: [sortDesc],
      },
    };
  }

  private setFilters(filters: Filters|undefined): void {
    this.filters = filters ?? [];

    if (filters === undefined || filters.length <= 0) {
      return;
    }

    if (typeof this.identifier === 'string') {
      sessionStorage.setItem(`filters.${this.identifier}`, JSON.stringify(filters.map(({ name, data, applied }) => ({ name, data, applied }))));
    }
  }

  private setPaginatorInfo(paginatorInfo: PaginatorInfo): void {
    this.firstItem = paginatorInfo?.firstItem;
    this.total = paginatorInfo?.total;
  }

  private getAppliedFilters(): Filters {
    const appliedFilters = this.filters.filter(({ data: _, applied }) => applied);

    return appliedFilters.map(({ name, data, applied }) => ({ name, data, applied }));
  }

  private static mapDatatable(list: Omit<List, 'paginatorInfo'>) {
    const dataTableMapper = new DataTableMapper(list.dataGridInfo, list.data);
    dataTableMapper.setColumnMetadata(list.dataGridInfo.metadata?.['column-settings'] as MetadataColumnsSettings);
    dataTableMapper.map();
    return dataTableMapper;
  }

  private getSorters(): Sorters {
    const array: Sorters = [];
    (this.options.sortBy ?? []).forEach((sortBy: string, index: number) => {
      if (sortBy === '') {
        return;
      }
      array[index] = {
        name: sortBy,
        direction: this.options.sortDesc?.[index] ? 'desc' : 'asc',
      };
    });

    return array;
  }

  public getDataTableMapper(): DataTableMapper {
    // type cast as quick fix build queue, should remove and apply proper logic
    return <DataTableMapper> this.dataTableMapper;
  }

  private getMetadata<MetaDataType>(key: string): MetaDataType {
    return <MetaDataType> this.metadata?.[key];
  }

  public getInlineEditableForms(): MetadataInlineEditableForms {
    return this.getMetadata<MetadataInlineEditableForms>('inline-editable-forms');
  }

  public getListSettings(): MetadataListSettings {
    return this.getMetadata<MetadataListSettings>('list-settings');
  }

  public getColumnSettings(): MetadataColumnsSettings {
    return this.getMetadata<MetadataColumnsSettings>('column-settings');
  }

  public getRowSettings(): MetadataRowSettings {
    return this.getMetadata<MetadataRowSettings>('row-settings');
  }

  public getReportSettings(): MetadataReportSettings {
    return this.getMetadata<MetadataReportSettings>('report-settings');
  }

  public getFileSettings(): MetadataFileSettings {
    return this.getMetadata<MetadataFileSettings>('file-settings');
  }

  public getModuleColumns(): MetadataModuleColumns {
    return this.getMetadata<MetadataModuleColumns>('module-columns');
  }

  public getAggregations(): MetadataAggregations {
    return this.getMetadata<MetadataAggregations>('aggregations');
  }

  public getQueryVariables(): { [key: string]: number | Sorters | Filters } {
    this.refreshPaginationOptions();

    const queryVariables: { [key: string]: number | Sorters | Filters } = {
      page: this.page,
      first: this.itemsPerPage,
      filters: this.getAppliedFilters(),
    };

    if (this.sorters.length > 0) {
      queryVariables.sorters = this.sorters;
    }

    return queryVariables;
  }
}
